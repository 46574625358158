@import url('https://fonts.googleapis.com/css2?family=Inter&family=Kenia&display=swap');
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #394c73; */
  background-image: url("./Resorces/background.jpg");
  background-attachment: fixed;
  background-size: 100%;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@media only screen and (max-width: 800px) {
  body {
    background-image: url("./Resorces/background-mobile.jpg");
  }

}
