@import url('https://fonts.googleapis.com/css2?family=Inter&family=Kenia&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:ital,wght@1,700&family=Lemonada:wght@700&family=Permanent+Marker&family=Yellowtail&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&family=Kenia&display=swap');



.pageTitle {
  font-family: 'Kenia', cursive;
  text-align: center;
  font-size: 4vw;
  color: rgb(161, 161, 161);
  padding: 0;
  margin-bottom: 0;
}

.pageDesc {
  text-align: center;
  color: rgb(161, 161, 161);
  font-size: x-large;
  font-family: 'Permanent Marker', cursive;
}



/* ↓↓ Navbar ↓↓ */
.navWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  color: white;
  min-height: 5vh;
  padding: 0 2vw;
  border-bottom: 1px solid teal;
}
.zviLogo {
  font-family: 'Yellowtail', cursive;
  color: white;
  text-decoration: none;
  font-size: x-large;
}

.navBarOptionText, .menuNavBarOptionText {
  color: white;
  text-decoration: none;
  padding: 0 1vw;
  margin: 0 1.5vw;
}

.menuNavBarOptionText{
  border-top: 1px solid teal;
  width: 100%;
  text-align: center;
  padding: 1vh;
}

.menuModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 5%;
  width: 100%;
  background-color: black;
  border: 1px solid teal;
}

.modalOverlay{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
}


.navBarOptionText:hover {
  color: teal;
}

.menuIcon{
  max-height: 5vh;
  display: none;
  cursor: pointer;
}

.contactButton {
  background-color: teal;
  border: 1px solid teal;
  border-radius: 5px;
  padding: 0.7vh 1vw;
}

.contactButton:hover {
  background-color: white;
}

/* ↓↓ Project Page ↓↓ */

.allProjectsWrap{
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-around;
  text-align: center;
}

.eachProjectWrap{
  background-color: rgba(42, 42, 42, 0.70);
  color: rgb(161, 161, 161);
  padding: 2vh 2vw;
  margin: 2vh 1vw;
  max-width: 40vw;
  border-radius: 5px;
  border: 1px solid teal;
}

.projectImg {
  max-width: 35vw;
}
.descWrap {
  max-width: 35vw;
}


.pageDesc > a {
  color: rgb(161, 161, 161);
}  

.projGitHubLink {
  color: rgb(161, 161, 161);
}


/* ↓↓ Contact page ↓↓ */

.ContactPageWrap{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactWrap{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: rgb(161, 161, 161);
  background-color: rgba(42, 42, 42, 0.397);
  height: 45vh;
  width: 80vw;
  border: 1px solid teal;
  margin-top: 10vh;
}
.contactWrap > a > img, .contactWrap > label > a > img, .contactWrap > label > img, .contactWrap > img {
  width: 15vw;
  margin-top: 5vh;
}



.contactWrap > label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactWrap > a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(161, 161, 161);
  text-decoration: none;
} 

.nameCopyWrap {
  padding-top: 2vh;
  display: flex;
  align-items: center;
}
.nameCopyWrap > img {
  margin-left: 0.5vw;
}

.copyIcon{
  max-width: 1.8vw;
  cursor: pointer;
}

/* ↓↓ Email Form ↓↓ */

.emailForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.star {
  color: red;
}

.fieldLabel {
  color: rgb(161, 161, 161);
  font-size: larger;
}

.formInput {
  margin: 0.2vh 0 2vh;
  width: 40vw;
  background-color: #000;
  color: #fff;
  border: none;
  border-bottom: 1px solid rgb(161, 161, 161);
  font-family: 'Inter', sans-serif;
  font-size: larger;
}

/* Chrome, Safari, Edge, Opera */
.emailForm > input::-webkit-outer-spin-button,
.emailForm > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.emailForm > textarea {
  height: 10vh;
}

.submitForm {
  background-color: #000;
  color: rgb(161, 161, 161);
  border: 1px solid rgb(161, 161, 161);
  font-size: larger;
  padding: 1vh 2vw;
  cursor: pointer;
  border-radius: 10px;
  margin: 1vh 0 5vh;
}

.submitForm:hover {
  background-color: rgb(161, 161, 161);
  color: #000;
  border: 1px solid #000;
}

.emailForm > div {
  display: flex;
}

.resMsg{
  margin-left: 2vw;
  color: rgb(161, 161, 161);
  max-width: 30vw;
}


/* ↓↓ Home Page ↓↓ */

.home {
  color-scheme: light;
}

.homeTitleWrap {
  display: flex;
  justify-content: center;
}

.homePageTitleLight {
  color: #ffffff;
}

.homePageTitleDark {
  color: #000;
}

.homePageTitle {
  /* font-family: 'Lemonada', cursive; */
  font-family: 'Averia Serif Libre', serif;
  font-size: 12vw;
  padding: 0;
  margin-bottom: 0;
  /* //////////////////////// */
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 10s linear infinite;
  display: inline-block;
  color-scheme: only light;
  
}
@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
 
/* /////////////////////// */

.homeSubtitle {
  /* font-family: 'Lemonada', cursive; */
  font-family: 'Averia Serif Libre', serif;
  display: flex;
  justify-content: center;
  position: relative;
}
.homeSubtitle span {
  position: relative;
  display: inline-block;
  font-size: 6vw;
  color: #fff;
  animation: flip 13s infinite;
  animation-delay: calc(.2s * var(--i))
}
@keyframes flip {
  0%,80% {
    transform: rotateY(360deg) 
  }
}

.spaceRight {
  margin-right: 3vw;
}

.homeBtnsWrap {
  display: flex;
  justify-content: space-around;
  margin-top: 10vh;
}


.homeBtns {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16vw;
  color: #fff;
  cursor: pointer;
  margin: 1vw;
  height: 15vh;
  padding: 1vh 1vw;
  text-align:center;
  border: none;
  background-size: 300% 100%;
  border-radius: 20px;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
    box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}

.homeBtns:hover {
  background-position: 100% 0;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.homeBtns:focus {
  outline: none;
}




.homeBtns > a {
  text-align: center;
  font-size: x-large;
  color: black;
  text-decoration: none;
  /* font-family: 'Playfair Display', serif; */
  font-weight: 600;
}



/* ↓↓ About Page ↓↓ */

.aboutPageContents {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.aboutPagePWrap > p {
  color: white;
  margin: 0 5vw;
  padding: 0;
  max-width: 50vw;
  text-align: justify;
  font-size: x-large;
}
.aboutPagePWrap {
  display: flex;
  align-items: center;
  margin: 2vh 0;
}

.aboutPagePWrap > img {
  max-width: 25vw;
  max-height: 35vh;
  border: 1px solid teal;
}

.imgBackground {
  padding: 1vh 0.5vw;
  background-color: rgba(43, 40, 40, 0.483);
  border-radius: 5px;
}

.codenationLink{
  color: white;
}

.codenationLink:hover {
  color: rgb(176, 176, 176);
}

.zviImg {
  max-width: 20vw;
  border-radius: 50%;
  margin-bottom: 2vh;
}

.lastText {
  color: white;
  margin: 0 5vw;
  padding: 0;
  max-width: 68vw;
  text-align: justify;
  font-size: x-large;
}

.cvDownloadLink {
  color: white;
}

.cvDownloadLink:hover {
  color: rgb(176, 176, 176);
}

/* ↓↓ Screen width Sizing ↓↓ */

@media only screen and (max-width: 800px) {
  .navBarOptionText {
    display: none; 
  }
  .aboutPagePWrap > p {
    
    flex-direction: column;
    text-align: justify;
    min-width: 70vw;
    padding: 0;
    margin: 0;
    font-size: larger;
  }

  .lastText > p {
    font-size: smaller;
  }

  .aboutPagePWrap > img {
    max-width: 80vw;
    max-height: 40vh;
    border: 1px solid teal;
    margin: 3vh 0;
  }

  .menuIcon{
    display: block;
    margin-left: 80%;
  }

  .eachProjectWrap {
    max-width: 90vw;
  }
  .projectImg {
    max-width: 85vw;
  }
  .descWrap {
    max-width: 85vw;
  }

  .contactWrap{
    display: flex;
    flex-direction: column;
    height: fit-content;
    padding-bottom: 2vh;
  }

  .copyIcon{
    max-width: 4vw;
    cursor: pointer;
  }

  .aboutPagePWrap {
    flex-direction: column;
  }

  .mobileColumnReverse {
    flex-direction: column-reverse;
  }

  .homeBtnsWrap {
    flex-direction: column;
    align-items: center;
  }

  .homeBtns > a {
    font-size: 5vw;
  }
  .homeBtns {
    width: 70vw;
    height: 9vh;
    margin: 2vh 0;
    
  }

  .homePageTitle {
    text-align: center;
    font-size: 20vw;
  }

  .emailForm{
    padding-left: 15vw;
  }

  .formInput {
    width: 70vw;
  }
  

}
 /* @media (prefers-color-scheme: dark) {
     .homePageTitle {
      color: #000;
     }
  } */